// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:shopping-bag-fill'),
    showTo: 'admin', // use all if want to show to all
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: getIcon('clarity:users-solid'),
    showTo: 'admin', // use all if want to show to all
  },
  {
    title: 'Supplier Report',
    path: '/dashboard/supplier-report',
    icon: getIcon('tabler:report-analytics'),
    showTo: 'supplier',
  },
  {
    title: 'Customer Validation Report',
    path: '/dashboard/customer-validation-report',
    icon: getIcon('streamline:user-check-validate'),
    showTo: 'supplier',
  },
  {
    title: 'Supplier List',
    path: '/dashboard/supplier-list',
    icon: getIcon('ph:user-list-bold'),
    showTo: 'admin',
  },
  {
    title: 'Product List',
    path: '/dashboard/product-list',
    icon: getIcon('cil:list-rich'),
    showTo: 'admin',
  },
  {
    title: 'Stock Report',
    path: '/dashboard/stock-report',
    icon: getIcon('ant-design:stock-outlined'),
    showTo: 'admin',
  },
];

export default navConfig;
