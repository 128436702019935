import { Navigate, useRoutes } from 'react-router-dom';
// layouts

import { useAuth0 } from '@auth0/auth0-react';
import DashboardLayout from './layouts/dashboard';

import Orders from './pages/Orders';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import OrderDetails from './pages/OrderDetails';
import SupplierReport from './pages/SupplierReport';
import SupplierList from './pages/SupplierList';
import ProductList from './pages/ProductList';
import ProductStockReport from './pages/ProductStockReport';
import StockReport from './pages/StockReport';
import UserList from './pages/UserList';
import AuthCheck from './components/AuthCheck';
import SupplierReportRedesigned from './pages/SupplierReportRedesigned';
import CustomerValidationReport from './pages/CustomerValidationReport';

export default function Router() {
  const { isAuthenticated, isLoading } = useAuth0();
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'users',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<UserList />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'users/add',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<Register />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'orders',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<Orders />}
              navigateRoute={'/login'}
            />
          ),
        },
        // { path: 'supplier-report', element: <AuthCheck isAuthenticated={isAuthenticated} isLoading={isLoading} children={<SupplierReport/>} navigateRoute={"/login"}/>},
        {
          path: 'supplier-report',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<SupplierReportRedesigned />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'customer-validation-report',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<CustomerValidationReport />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'supplier-list',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<SupplierList />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'product-list',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<ProductList />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'stock-report',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<StockReport />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'product-list/stock/:id',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<ProductStockReport />}
              navigateRoute={'/login'}
            />
          ),
        },
        {
          path: 'orders/:id',
          element: (
            <AuthCheck
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              children={<OrderDetails />}
              navigateRoute={'/login'}
            />
          ),
        },
      ],
    },
    {
      path: '/',
      children: [
        { path: '/', element: <Navigate to={isAuthenticated ? '/dashboard/orders' : '/login'} /> },
        { path: 'login', element: isAuthenticated ? <Navigate to="/dashboard/orders" /> : <Login /> },
        // { path: 'register', element: isAuthenticated ? <Navigate to="/dashboard/orders" /> : <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
